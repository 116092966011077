import React from "react"
import { Link } from "gatsby"

export default function InProgress({pageName}) {
  return (
    <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
      <h1 className="text-5xl md:text-6xl leading-tight font-bold text-gray-800">
        {pageName} Page
      </h1>
      <h1 className="text-5xl md:text-6xl leading-tight font-bold text-brand-green mb-5">
        is in progress.
      </h1>
      <Link to="/">
        <div className="btn w-64 text-center">Take me home</div>
      </Link>
    </div>
  )
}
